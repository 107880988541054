<template>
  <v-container id="carrier-sales-rep-dashboard" fluid>
    <iframe
      :src="carrierSalesRepDashboardIframeUrl"
      frameborder="0"
      width="100%"
      height="800"
      allowtransparency
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "CarrierSalesRepDashboard",
  computed: {
    ...mapGetters("metabase", {
      carrierSalesRepDashboardIframeUrl: "carrierSalesRepDashboardIframeUrl",
    }),
  },
  mounted() {
    this.$store.dispatch("generic/GET_AGENT_DETAIL").then((response) => {
      this.$store.dispatch(
        "metabase/MOUNT_CARRIER_SALES_REP_DASHBOARD",
        response
      )
    })
  },
}
</script>
